// Import dependencies
import React from "react"
import { useState } from "react"
import { navigate } from "gatsby"

import {PageContainer} from "../../styled-components/UILibrary";
import RecycleBrands from "../../assets/images/recycle_brands.png";
import RecycleGeneric from "../../assets/images/recycle_generic1.png";
import StarBanner from "../../assets/images/star_banner.png";
import GeneralButton from "../form/buttons/GeneralButton";
import DrawerDynamicContent from "../cart/drawer-dynamic";
import Image from './brandImg';

import {connect} from "react-redux";
import Fuse from 'fuse.js';

import "./department-content.css";

/*
    Item for What makes us different component from the index page
*/
const GalleriaAll = ({ brands }) => {

  const [brandDrawerOpen, setBrandDrawerOpen] = useState(false);
  const toggleBrandDrawerOpen = () => {
      setBrandDrawerOpen(!brandDrawerOpen);
  }

  const [categoryDrawerOpen, setCategoryDrawerOpen] = useState(false);
  const toggleCategoryDrawerOpen = () => {
      setCategoryDrawerOpen(!categoryDrawerOpen);
  }

    return (  <>
      <div>
        <Image filename={brands + "_logo.png"} imgtype="logo"/>
      </div>
    </>);
}
export default GalleriaAll;
